.card {
  width: 500px;
  height: 250px;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: linear-gradient(
    45deg,
    #ffdba5,
    #ffb94f,
    #ff9801,
    #ffb94f,
    #ffdba5
  );
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out, height 0.3s ease-in-out,
    width 0.3s ease-in-out;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 505px;
  height: 255px;
}

.title {
  font-weight: bold;
  text-align: center;
}

.description {
  text-align: justify;
}
