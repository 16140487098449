.service-page-container {
  /* display: flex; */
  /* position: fixed; */
  /* bottom: 20px;
  right: 20px; */
  /* opacity: 0; */
  transition: opacity 0.5s ease-in-out;
}

.card-container {
  display: flex;
  /* flex-wrap: "wrap"; */
  flex-direction: row;
  justify-content: center;
}
