.recent-highlight-container {
  overflow: hidden;
  height: auto;
  padding: 5px 0;
  width: 100%;
  white-space: nowrap;
}

.events-wrapper {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: max-content;
  min-width: 100%;
  animation: scroll 20s linear infinite;
}
.events-wrapper:hover {
  animation-play-state: paused;
}

.event {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  position: relative;
}
.event:hover {
  background-color: #cfbf7c;
}

.important-event {
  background-color: #ffcc00;
  background: linear-gradient(
    45deg,
    #fbdbab,
    #fcba57,
    #ff9801,
    #fcba57,
    #fbdbab
  );
}

.highlight-loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
  left: 0;
  top: 0;
  z-index: 1;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 767px) {
  .recent-highlight-container {
    max-height: 100%;
    padding: 2px 0;
  }

  .event {
    padding: 2px;
  }
}
