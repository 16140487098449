.carousel {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  /* animation: rotate 9s infinite; */
}

.slide {
  display: none;
  width: 100%;
  transition: opacity 1s ease-in-out;
}

.slide.active {
  display: block;
}

.slide img {
  width: 100%;
  height: auto;
  max-height: 100vh;
}

/* Animation */
@keyframes rotate {
  0%,
  100% {
    opacity: 0;
    z-index: 1;
  }
  10%,
  90% {
    opacity: 1;
    z-index: 2;
  }
}
