.admin-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Optional: Style for responsive design */
@media (max-width: 600px) {
  .admin-container {
    width: calc(50% - 20px);
  }
}
