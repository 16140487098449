.toast-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.toast-container.show {
  opacity: 1;
}

.toast {
  display: flex;
  background-color: #333;
  color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
}

.toast-icon {
  font-size: 24px;
  margin-right: 10px;
}

.toast-content {
  flex: 1;
}

.toast-message {
  margin: 0;
}

.toast-progress {
  height: 4px;
  background-color: #4caf50;
  width: 100%;
  margin-top: 8px;
  border-radius: 4px;
  animation: progressAnimation 8s linear forwards;
}

@keyframes progressAnimation {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
