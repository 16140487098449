.contact-form {
  background-color: #f4f4f4;
  margin: 5px auto;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#social-contact {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  text-align: center;
  margin: 20px;
}

.social-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.social-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 10px;
}

.profile-highlight {
  margin: 5px;
  font-size: 14px;
  color: #555;
}

#email-contact {
  width: 50%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

h2 {
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
}

input {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 80px;
  resize: vertical;
}

button {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

@media (max-width: 767px) {
  .contact-form {
    /* background-color: #f4f4f4; */
    /* margin: auto; */
    /* background-color: #fff; */
    /* display: flex; */
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
  }

  #social-contact {
    flex-grow: 1;
    width: max-content;
  }

  #email-contact {
    flex-grow: 1;
    width: 95%;
  }
}
