.navbar {
  background-color: transparent;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1000;
  min-height: 5%;
  padding: 0;
  transition: background-color 0.3s ease;
}

.stretch-item {
  flex-grow: 1;
  align-self: stretch;
  padding: 5px;
}

.navbar:hover {
  background-color: #ff9801;
}

.logo-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.logo-container img {
  height: 100%;
  width: 76px;
  margin-right: 10px;
  margin-left: 1px;
  border-radius: 50px;
}

.logo-container a {
  text-decoration: none;
  color: black;
  width: max-content;
  transition: background-color 0.3s ease;
}

.nav-links {
  list-style-type: none;
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links a {
  text-decoration: none;
  font-size: large;
  color: rgb(203, 71, 71);
  transition: background-color 0.3s ease;
}

/* Change background on hover */
.nav-links a:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  padding: 5px;
}

.scrolled {
  background-color: #ffd699;
  transition: background-color 0.5s ease;
}

.dropdown {
  position: relative;
  display: flex;
  padding: 0 10px;
}

.dropdown-toggle {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: none;
}
.dropdown-menu a {
  text-decoration: none;
}

.menu-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.menu-item:last-child {
  border-bottom: none;
}

/* Show the dropdown menu when the button is clicked */
.dropdown:hover .dropdown-menu {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

@media (max-width: 767px) {
  .nav-links {
    display: none;
  }

  .dropdown {
    display: inline-block;
  }

  .navbar {
    min-height: 2%;
  }

  .logo-container img {
    width: 50px;
    margin-right: 1px;
  }

  .logo-container a {
    display: none;
  }
}
