.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 20px; */
  height: 100%;
  width: 100%;
  background-color: burlywood;
}

.loader {
  font-size: 48px;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #fff;
  letter-spacing: 2px;
  position: relative;
  box-sizing: border-box;
}
.loader::after {
  content: "HM Series";
  position: absolute;
  left: 0;
  top: 0;
  color: #263238;
  text-shadow: 0 0 2px #fff, 0 0 1px #fff, 0 0 1px #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  animation: animloader 6s linear infinite;
}

@keyframes animloader {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}
