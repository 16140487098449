.about {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #f4f4f4;
}

.about-content {
  max-width: 80%;
  margin: 10px auto 10px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}
.about-content a {
  text-decoration: none;
}

h1,
h2 {
  color: #333;
}

.services-list {
  list-style-type: none;
  padding: 0;
}

.services-list li {
  margin-bottom: 20px;
}

.services-list strong {
  color: #007bff;
}
